import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';

const ProiecteWeb = () => {
    return (
        <Layout>
            <DoubleLayout>
                <h1>Partea de proiecte web</h1>
            </DoubleLayout>
        </Layout>
    );
}
export default ProiecteWeb;